export enum CancellationReasonType {
  Lost = 1,
  NotPickedUpAtPickupPoint = 2,
  ContainsProhibitedItem = 3,
  UndeliveredBySeller = 4,
  MarketplaceCancellation = 5,
  BuyerRejection = 6,
  LastMileNotApproved = 7,
  SellerRejection = 8,
  Oversized = 9,
  ProhibitedByCustoms = 10,
  Reexport = 11,
  ProhibitedByCustomsIncorrectPassportData = 12,
  LostFistMile = 13,
  LostExportCustoms = 14,
  LostImportCustoms = 15,
  LostLastMileMile = 16,

  CustomsTerminationOther = 18,
  CustomsTerminationNoValidPassportData = 19,
  CustomsTerminationNoRequiredDocuments = 20,
  CustomsTerminationCommercialOrNonPersonalUse = 21,
  CustomsTerminationUnpaidCustomsDuty = 22
}
