//@ts-ignore
export const CLIENT_ID = window._env_.CLIENT_ID;
//@ts-ignore
export const API_URL = window._env_.API_URL;
//@ts-ignore
export const ROOT_HOST = window._env_.ROOT_HOST;
//@ts-ignore
export const AUTH_URL = window._env_.AUTH_URL;
//@ts-ignore
export const SCOPE = window._env_.SCOPE;
//@ts-ignore
export const KEY = window._env_.KEY