import React from "react";
import "./TextArea.css";

interface TextAreaProps {
  value: string;
  onChange: (value: string) => void;
}

const TextArea = (props: TextAreaProps) => {
  return (
    <textarea
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      className="text-area"
      rows={0}
      cols={50}
      placeholder={"Enter tracking or order number"}
    />
  );
};

export default TextArea;
