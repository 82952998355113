import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faCoffee,
  faExchange,
  faPlaneArrival,
  faPlaneDeparture,
  fas,
  faShop,
  faTruck,
  faUsers,
  faTriangleExclamation,
  faPersonCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fas,
  faExchange,
  faCoffee,
  faUsers,
  faCheck,
  faTruck,
  faPlaneDeparture,
  faPlaneArrival,
  faShop,
  faTriangleExclamation,
  faPersonCircleCheck
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
