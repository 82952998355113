import { FormControlLabel,Checkbox,Autocomplete,TextField } from '@mui/material'
import axios from 'axios'
import { SyntheticEvent, useState } from 'react'
import { API_URL } from '../../constants'
import { handlerNumbers } from '../../handler/TextAreaHandler'
import { getTypeHalper, HalperData, HalperProblemType } from '../../types/Halper'
import Button from '../Button/Button'
import TextArea from '../TextArea/TextArea'
import './Halper.css'
export interface Props {
    dataNumbers:Array<string>
    lang:string,
    onClose:()=>void
}
const Bacground = (props : Props) =>{
    const [isCheck, setIsCheck] = useState(false)
    const [halperData, setHalperData] = useState<HalperData>({dataNumbers:[], description:"", typeId:0})
    const [value, setValue] = useState<string>("")
    const [typeProblem] = useState<HalperProblemType[]>(getTypeHalper(props.lang))
    const handlerCheckBox = () =>{
        if(!isCheck) setHalperData({...halperData, dataNumbers:props.dataNumbers})
        else setHalperData({...halperData, dataNumbers:[]})
        setIsCheck(!isCheck)
    }
    const handlerText = (value:string)=>{
        setValue(value)
        const numbers = handlerNumbers(value)
        setHalperData({...halperData, dataNumbers:[...numbers.externalNumbers, ...numbers.trackNumbers]})
    }
    const sendingHandler = ()=>{
        const options = {
            method: 'post',
            url: API_URL+'/v1/public/problems',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
              },
            data: {
              numbers: halperData.dataNumbers,
              message: halperData.description,
              type:halperData.typeId
            }}
        axios(options)
        props.onClose()
    }
    const handlerProblemChange = (value:HalperProblemType|null ) =>{
        if(value !==null){
            setHalperData({...halperData, typeId:value.id})
        }else setHalperData({...halperData, typeId:0})
    }
    const viewHandler = () =>{
        if(props.dataNumbers.length === 0){
            return( <TextArea value={value} onChange={handlerText}   />)
        }else{
            return(<><FormControlLabel style={{margin:"10px 0"}} label={props.lang === "ru"?"Выбрать все":"Choose all"} control={<Checkbox onChange ={handlerCheckBox}  />}/>
            {isCheck === false ?
            <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={props.dataNumbers}
            getOptionLabel={(option) => option}
            onChange={(_:SyntheticEvent, value:string[])=>{ setHalperData({...halperData, dataNumbers:value})}}
            renderInput={(params) => (
                <TextField {...params} label={props.lang === "ru"?"Выбрать номер":"Select number"} placeholder={props.lang === "ru"?"Выбрать номер":"Select number"} />
            )}
            />:""}</>)
        }
    }
    return (<div className={"layout"} >
        <div className={"selector"}>
            <div className={"pd-10"}>
                {viewHandler()}
                <Autocomplete
                sx={{margin:"20px 0 0 0"}}
                id="multiple-limit-tags"
                options={typeProblem}
                getOptionLabel={(option) => option.text}
                onChange={(_:SyntheticEvent, value:HalperProblemType|null)=>{ handlerProblemChange(value)}}
                renderInput={(params) => (
                    <TextField {...params} label={props.lang === "ru"?"Выбрать тип проблемы":"Type problem"} placeholder={props.lang === "ru"?"Выбрать тип проблемы":"Type problem"} />
                )}
                />
            </div>
            <div className={"pd-10"}> 
                <TextField
                sx={{width:'100%'}}
                label={props.lang === "ru"?"Описать проблему":"Describe the problem"}
                multiline
                rows={6}
                variant="standard"
                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setHalperData({...halperData, description:e.target.value})}}
                />
            </div>
            <div className={"pd-10"}>
                <Button type='submit' text={props.lang === "ru"?"Отправить":"Send"} onClick={sendingHandler} disabled={ halperData.description !== '' && halperData.dataNumbers.length && halperData.typeId !== 0 ? false: true} ></Button>
                <Button type='button' text={props.lang === "ru"?"Отмена":"Cancel"} onClick={props.onClose} disabled={false}></Button>
            </div>
        </div>
    </div>)
}

export default Bacground