export const  handlerNumbers = (value:string) =>{
    let trackNumberPattern = /\w\w\w\d\d\d\d\d\d\d\w\w/i;
    const space = value.indexOf(" ");
    const newLine = value.indexOf("\n");
    let trackNumbersArray: string[] = [];
    let externalNumbersArray: string[] = [];
    let successivelyArray: string[]=[];
    let splitValue: string[] = [];
  

    if (newLine > 0) {
      splitValue = value.split("\n");
    } else if (space > 0) {
      splitValue = value.split(" ");
    }

    if (splitValue.length >= 0) {
      splitValue.forEach((item) => {
        if (trackNumberPattern.test(item)) {
          trackNumbersArray.push(item);
        } else if (item.trim() !== "") {
          externalNumbersArray.push(item);
        }
        successivelyArray.push(item)
      });
      
    }

    if (splitValue.length === 0 && value.trim().length > 0) {
      successivelyArray.push(value.trim())
      if (trackNumberPattern.test(value.trim())) {
        trackNumbersArray.push(value.trim());
      } else {
        externalNumbersArray.push(value.trim());
      }
    }
    return {trackNumbers: trackNumbersArray ,externalNumbers:externalNumbersArray, successivelyArray }
}