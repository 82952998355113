import React from "react";
import "./Parcel.css";
import { Parcel as ParcelType } from "../../types/Parcel";
import { TrackEvent } from "../../types/TrackEvent";
import moment from "moment";
import ParcelEvent from "./ParcelEvent";
import classNames from "classnames";
import { TrackEventType } from "../../types/TrackEventType";

interface ParcelProps {
  parcel: ParcelType;
  lang: string;
}

const Parcel = (props: ParcelProps) => {
  const isDelivery = (trackEvents: TrackEvent[]): boolean => {
    let isDelivery = false;

    trackEvents.forEach((e) => {
      if (
        e.type === TrackEventType.Delivered ||
        e.type === TrackEventType.DeliveredByCourier
      )
        isDelivery = true;
    });

    return isDelivery;
  };

  const isCancel = (trackEvents: TrackEvent[]): boolean => {
    let isCancel = false;

    trackEvents.forEach((e) => {
      if (e.type === TrackEventType.Canceled) isCancel = true;
    });

    return isCancel;
  };

  return (
    <div
      className={classNames({
        parcel: true,
        "parcel--delivery": isDelivery(props.parcel.trackEvents),
        "parcel--cancel": isCancel(props.parcel.trackEvents),
      })}
    >
      <div className={"parcel__head"}>
        <div className={"parcel__track-number"}>
          Track number: {props.parcel.trackNumber}
        </div>
        <div className={"parcel__external-number"}>
          Order number:{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            {props.parcel.externalNumber}
          </span>
        </div>
      </div>
      <div className={"parcel__events"}>
        <ParcelEvent
          lang={props.lang}
          type={1}
          moment={moment(props.parcel.createdAt).format("DD.MM.YY HH:mm")}
        />
        {props.parcel.trackEvents
          .sort((a, b) => {
            return moment(a.moment).unix() - moment(b.moment).unix();
          })
          .filter((item, index) => {
            return (
              props.parcel.trackEvents.map((x) => x.type).indexOf(item.type) ===
              index
            );
          })
          .map((event, index) => (
            <ParcelEvent
              key={index}
              lang={props.lang}
              type={event.type}
              cancellationReasonType={event.cancellationReasonType}
              moment={moment(event.moment).format("DD.MM.YY HH:mm")}
            />
          ))}
      </div>
    </div>
  );
};

export default Parcel;
