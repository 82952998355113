import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { API_URL } from "./constants";
import TextArea from "./components/TextArea/TextArea";
import Button from "./components/Button/Button";
import Parcel from "./components/Parcel/Parcel";
import { Parcel as ParcelType } from "./types/Parcel";
import { RequestState } from "./types/RequestState";
import ButtonSvg from "./components/ButtonSvg/ButtonSvg"
import {ReactComponent as ProbleSvg}  from './assets/customer.svg'
import {utils, writeFileXLSX } from 'xlsx';
import moment from "moment";
import {  getFriendlyName } from "./types/TrackEventType";
import Bacground from "./components/Halper/Halper";
import { handlerNumbers } from "./handler/TextAreaHandler";
function App() {
  const [lang, setLang] = useState<string>("en");
  const [parcels, setParcels] = useState<ParcelType[]>([]);
  const [value, setValue] = useState<string>("");
  const [trackNumbers, setTrackNumbers] = useState<string[]>([]);
  const [successively, setSuccessively] = useState<string[]>([]);

  const [getParcelsRequest, setSetParcelsRequest] = useState<RequestState>(
    RequestState.Init
  );
  const [externalNumbers, setExternalNumbers] = useState<string[]>([]);
  const [notFoundNumbers, setNotFoundNumbers] = useState<string[]>([]);
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [halperIsOpen, setHalperIsOpen] = useState<boolean>(false)
  useEffect(() => {
    let host = window.location.host;
    let subdomain = host.split(".")[0];
    setName(subdomain);
    let userLanguage = window.navigator.language.slice(0, 2);
    if (userLanguage === "ru") setLang("ru");
  }, []);

  useEffect(() => {
    let trackNumbersFromParcels = parcels.map((x) => x.trackNumber);
    let externalNumbersFromParcels = parcels.map((x) => x.externalNumber);

    let diffTrackNumbers = trackNumbers.filter(
      (x) => trackNumbersFromParcels.indexOf(x) < 0
    );
    let diffExternalNumbers = externalNumbers.filter(
      (x) => externalNumbersFromParcels.indexOf(x) < 0
    );

    setNotFoundNumbers([...diffExternalNumbers, ...diffTrackNumbers]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcels]);

  const handleChange = (value: string) => {
    setValue(value);
    setTrackNumbers([]);
    setExternalNumbers([]);
    const numbers = handlerNumbers(value)
    setTrackNumbers(numbers.trackNumbers)
    setExternalNumbers(numbers.externalNumbers)
    setSuccessively(numbers.successivelyArray)
  };

  const handleClick = () => {
    setError("");
    if (trackNumbers.length === 0 && externalNumbers.length === 0) return;

    let config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    if (trackNumbers.length + externalNumbers.length > 99) {
      setError(
        lang === "ru" ? "Не более 100 номеров" : "No more than 100 numbers"
      );
      return;
    }

    setSetParcelsRequest(RequestState.Pending);

    let filter =
      "?trackNumbers=" +
      JSON.stringify(trackNumbers) +
      "&externalNumbers=" +
      JSON.stringify(externalNumbers);

    axios
      .get(API_URL + "/v1/public/track-events" + filter, config)
      .then((x) => x.data)
      .then((response) => {
        const sortObj = new Array<ParcelType>()
        successively.forEach(item =>{
          sortObj.push(...response.filter((x: ParcelType) => x.trackNumber === item || x.externalNumber === item))
        })
        setParcels([...sortObj]);
       
        setSetParcelsRequest(RequestState.Done);
      })
      .catch((error) => {
        setError(error);
        setSetParcelsRequest(RequestState.Error);
      });
  };
  const handlerXlsx = ()=>{
    if(parcels.length){
    
      const dataArray = new Array<Object>()
      let trackEvent:string;
      let trackEventDate:string;
      parcels.forEach(item => {
        let rocket = "NO"
        item.trackEvents.forEach(ev =>{
          if(rocket !== "YES") 
          rocket = ev.type === 401 ? "YES" : "NO"
          
        })
        if(item.trackEvents.length){
          trackEvent = getFriendlyName(lang, item.trackEvents[item.trackEvents.length-1].type)
          trackEventDate = moment( item.trackEvents[item.trackEvents.length-1].moment).format("DD.MM.YY HH:mm")
        }
        else{
          trackEvent = getFriendlyName(lang, 1)
          trackEventDate = moment( item.createdAt).format("DD.MM.YY HH:mm")
        } 
        let object = {
          TrackNumber: item.trackNumber,
          OrderNumber: item.externalNumber,
          DateEvent:trackEventDate,
          Message: trackEvent,
          Rocket:rocket
        }
        dataArray.push(object)
      })
      const ws = utils.json_to_sheet(dataArray);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `data.xlsx`);
    
    } 
  }
  const handlerIsOpenWnd = ()=>{
    if(!halperIsOpen === true)
      document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'initial'  

    setHalperIsOpen(!halperIsOpen)
  }

  return (
    <div className="app">
      <div className="app-container">
        <div className={"app__logo"}>{name}</div>
        <div className="app__head">
          <div className={"app__text-area"}>
            <TextArea value={value} onChange={handleChange} />
          </div>
          <div className={"app__button"}>
            <Button
              type="button"
              disabled={getParcelsRequest === RequestState.Pending}
              onClick={handleClick}
              text="TRACE"
            />
            {parcels.length !==0 ? <Button
              disabled={getParcelsRequest === RequestState.Pending}
              onClick={handlerXlsx}
              text="Download xlsx"
              type="button"
            />:"" }
          </div>
          {getParcelsRequest === RequestState.Pending && (
            <div className={"app__loader"}>
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {notFoundNumbers.length !== 0 && (
            <div className={"app__notfound"}>
              <div style={{ padding: "5px 0", fontWeight: "600" }}>
                {lang === "ru" ? "Не найдено:" : "Not found:"}
              </div>
              {notFoundNumbers.map((x,index) => (
                <span key={index}>{x} </span>
              ))}
            </div>
          )}
          {error.length > 0 && <div className={"app__error"}>{error}</div>}
        </div>

        <div className="app__parcels">
          {parcels.map((parcel,index) => (
            <Parcel key={index} parcel={parcel} lang={lang} />
          ))}
        </div>
      </div>
      {/* <ButtonSvg onClick={handlerIsOpenWnd} svgImg={ProbleSvg}/> */}
      {halperIsOpen ?<Bacground onClose={handlerIsOpenWnd}  lang={lang} dataNumbers={[...trackNumbers,...externalNumbers] }/>:"" }
      
    </div>
  );
}

export default App;
