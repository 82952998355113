
export interface HalperData{
    dataNumbers:Array<string>,
    typeId:number,
    description:string
}
export enum HalperType {
    // По посылки долго нет движения
    NoMovement = 1,
    // Посылка не найдена
    NotFound = 2,
    // Другое
    Other = 3
}
export interface HalperProblemType {
    id:number,
    text:string
}
export const getTypeHalper = (lang:string):HalperProblemType[]=>{    
    const  typeHalper = new Array<HalperProblemType>()
    const idHalper = Object.keys(HalperType).filter(x=> !isNaN(Number(x)))
    if(lang === "ru"){
        idHalper.forEach(x=>{
            let object:HalperProblemType
            switch(Number(x)){
                case HalperType.NoMovement:
                    object = {id:HalperType.NoMovement, text: "Долго нет движения"}
                    break
                case HalperType.NotFound:
                    object = {id:HalperType.NotFound, text: "Посылка не найдена"}
                    break
                case HalperType.Other:
                    object = {id:HalperType.Other, text: "Другое"}
                    break
                default: return[]
            }
            typeHalper.push(object)
        })
    }
    if(lang === "en"){
        idHalper.forEach(x=>{
            let object:HalperProblemType
            switch(Number(x)){
                case HalperType.NoMovement:
                    object = {id:HalperType.NoMovement, text: "No parcel movement"}
                    break
                case HalperType.NotFound:
                    object = {id:HalperType.NotFound, text: "Not found"}
                    break
                case HalperType.Other:
                    object = {id:HalperType.Other, text: "Other"}
                    break
                default: return[]
            }
            typeHalper.push(object)
        })
    }
    return typeHalper
}