export enum TrackEventType {
  Intermediate = 0,
  Await = 1,

  ConsolidationDropOffAccepted = 50,
  FirstMileAccepted = 101,
  FirstMileCompleted = 150,

  ExportCustomsAccepted = 201,
  ExportCustomsOnTheWay = 203,
  ExportCustomsCompleted = 250,

  ImportCustomsAccepted = 301,
  ImportCustomsPassportVerificationStarted = 305,
  ImportCustomsPassportVerificationCompleted = 306,
  ImportCustomsReadyToPickUp = 340,
  ImportCustomsCompleted = 350,

  LastMileAccepted = 401,
  LastMileDeliveredToPickUpPoint = 404,

  /// <summary>
  /// Передано в службу доставки, курьеру
  /// </summary>
  LastMileSubmittedForCourierDelivery = 405,

  ChinaPostAccepted = 501,
  ChinaPostCompleted = 550,

  Canceled = 5000,

  /// <summary>
  /// Покупатель забрал из точки выдачи
  /// </summary>
  Delivered = 10000,

  /// <summary>
  /// Доставлено курьером
  /// </summary>
  DeliveredByCourier = 10001,
}

export const getFriendlyName = (lang: string, type: TrackEventType): string => {
  if (lang === "en") {
    switch (type) {
      case TrackEventType.Await:
        return "Awaiting shipment to the warehouse";
      case TrackEventType.ConsolidationDropOffAccepted:
        return "Accepted in Consolidated Warehouse";
      case TrackEventType.ChinaPostAccepted:
        return "Arrived at the China Post office";
      case TrackEventType.ChinaPostCompleted:
        return "Released from the China Post";
      case TrackEventType.FirstMileAccepted:
        return "Entered the warehouse of the first mile";
      case TrackEventType.FirstMileCompleted:
        return "Sent to customs - export";
      case TrackEventType.ExportCustomsAccepted:
        return "Entered the customs - export";
      case TrackEventType.ExportCustomsOnTheWay:
        return "On the way - export"
      case TrackEventType.ExportCustomsCompleted:
        return "Passed customs - export";
      case TrackEventType.ImportCustomsPassportVerificationStarted:
        return "Passport verification started";
      case TrackEventType.ImportCustomsPassportVerificationCompleted:
        return "Passport verification completed";
      case TrackEventType.ImportCustomsReadyToPickUp:
        return "Ready for shipment - import";
      case TrackEventType.ImportCustomsAccepted:
        return "Entered the customs - import";
      case TrackEventType.ImportCustomsCompleted:
        return "Passed customs - import";
      case TrackEventType.LastMileAccepted:
        return "Entered the warehouse of the last mile";
      case TrackEventType.LastMileDeliveredToPickUpPoint:
        return "Available at the point of pick up";
      case TrackEventType.LastMileSubmittedForCourierDelivery:
        return "Transferred to the courier service";
      case TrackEventType.Delivered:
        return "Delivered";
      case TrackEventType.DeliveredByCourier:
        return "Delivered";
      case TrackEventType.Canceled:
        return "Canceled";
      default:
        return "-";
    }
  }

  if (lang === "ru") {
    switch (type) {
      case TrackEventType.Await:
        return "Ожидает отправки на склад";
      case TrackEventType.ConsolidationDropOffAccepted:
        return "Поступил на склад консолидации";
      case TrackEventType.FirstMileAccepted:
        return "Поступил на склад первой мили";
      case TrackEventType.FirstMileCompleted:
        return "Отправлен на таможню - экспорт";
      case TrackEventType.ChinaPostAccepted:
        return "Прибыл на почту Китая";
      case TrackEventType.ChinaPostCompleted:
        return "Покинул почту Китая";
      case TrackEventType.ExportCustomsAccepted:
        return "Поступил на таможню - экспорт";
      case TrackEventType.ExportCustomsOnTheWay:
        return "В пути - экспорт"
      case TrackEventType.ExportCustomsCompleted:
        return "Прошёл таможню - экспорт";
      case TrackEventType.ImportCustomsPassportVerificationStarted:
        return "Проверка паспортных данных";
      case TrackEventType.ImportCustomsPassportVerificationCompleted:
        return "Проверка паспортных данных завершена успешно";
      case TrackEventType.ImportCustomsReadyToPickUp:
        return "Подготовлено к отгрузке - импорт";
      case TrackEventType.ImportCustomsAccepted:
        return "Поступил на таможню - импорт";
      case TrackEventType.ImportCustomsCompleted:
        return "Прошёл таможню - импорт";
      case TrackEventType.LastMileAccepted:
        return "Поступил на склад последней мили";
      case TrackEventType.LastMileDeliveredToPickUpPoint:
        return "Доступен в точке выдачи";
      case TrackEventType.LastMileSubmittedForCourierDelivery:
        return "Передан в курьерскую службу";
      case TrackEventType.Delivered:
        return "Доставлен";
      case TrackEventType.DeliveredByCourier:
        return "Доставлен";
      case TrackEventType.Canceled:
        return "Отменен";
      default:
        return "-";
    }
  }

  return "";
};
