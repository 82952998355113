import React from 'react';
import "./Button.css"

interface ButtonProps{
    onClick:()=>void|Event
    text:string
    disabled:boolean
    type:"button"|"submit"|"reset"
}

const Button = (props:ButtonProps) => {
    return (
        <button disabled={props.disabled} type={props.type} className={"button"} onClick={props.onClick}>
             {props.text}
        </button>
    );
};

export default Button;