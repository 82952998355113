import React from "react";
import "./ParcelEvent.css";
import { getFriendlyName, TrackEventType } from "../../types/TrackEventType";
import { CancellationReasonType } from "../../types/CancellationReasonType";

interface ParcelEventProps {
  type: TrackEventType;
  moment: string;
  lang: string;
  cancellationReasonType?: CancellationReasonType;
}

const ParcelEvent = (props: ParcelEventProps) => {
  return (
    <div className={"parcel-event"}>
      <div className={"parcel-event__date"}>{props.moment}</div>
      <div className={"parcel-event__type"}>
        {getFriendlyName(props.lang, props.type)}{" "}
        {props.cancellationReasonType && (
          <span> ({CancellationReasonType[props.cancellationReasonType]})</span>
        )}
      </div>
    </div>
  );
};

export default ParcelEvent;
